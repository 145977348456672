import { LOCAL_STORAGE_KEY_LOGGED_ADMIN, LOCAL_STORAGE_KEY_LOGGED_ADMIN_TOKEN } from '@/assets/ts/variables/auth';
import { defineComponent, ref } from 'vue';
import Modal from '@/components/layout/modal/Modal.vue';
import { loginAdministrator } from '@/assets/ts/api/auth';
import TextFilter from '@/components/core/filters/text-filter/TextFilter.vue';
export default defineComponent({
    components: {
        Modal,
        TextFilter
    },
    emits: ['logged-admin'],
    setup(props, context) {
        const loginInput = ref();
        const passwordInput = ref();
        const authModalRef = ref();
        const authModalMessage = ref('');
        function login() {
            const authData = {
                login: loginInput.value.getInputValue(),
                password: passwordInput.value.getInputValue()
            };
            authModalMessage.value = '';
            loginAdministrator(authData).then((results) => {
                const loggedAdmin = results.admin;
                saveAdminDataIntoLocalStorage(loggedAdmin, results.token);
                authModalMessage.value = 'Administrator poprawnie zalogowany!';
                context.emit('logged-admin', loggedAdmin);
            }).catch((err) => {
                authModalMessage.value = 'Błąd logowania do panelu administratora!';
                console.error(`loginAdministrator(${authData}) err`, err);
                authModalRef.value.toggleModal();
            });
        }
        function saveAdminDataIntoLocalStorage(adminData, token) {
            localStorage.setItem(LOCAL_STORAGE_KEY_LOGGED_ADMIN, JSON.stringify(adminData));
            localStorage.setItem(LOCAL_STORAGE_KEY_LOGGED_ADMIN_TOKEN, JSON.stringify(token));
        }
        return { authModalRef, authModalMessage, login, loginInput, passwordInput };
    }
});
