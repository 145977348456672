import { defineComponent, onMounted, ref } from 'vue';
import AdminPanelAuth from '@/components/panel/panel-auth/AdminPanelAuth.vue';
import Subpage from '@/components/layout/subpage/Subpage.vue';
import { getLoggedAdmin } from '@/assets/ts/helpers/admin';
export default defineComponent({
    components: {
        AdminPanelAuth,
        Subpage
    },
    setup() {
        const subpage = ref();
        const loggedAdmin = ref(null);
        onMounted(() => loggedAdmin.value = getLoggedAdmin());
        function successfulLogin() {
            loggedAdmin.value = getLoggedAdmin();
            subpage.value.updateAdminInfo();
        }
        return {
            loggedAdmin,
            successfulLogin,
            subpage
        };
    }
});
